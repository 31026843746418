.ant-collapse-header {
  /* border-radius: 50px !important; */
}
.drawer-title {
  font-weight: bold;
}
.ask-for-help-btn {
  position: fixed;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}
.help-btn {
  cursor: pointer;
  background-color: #001529;
  border-radius: 5px;
  color: #fff;
  padding: 0.8rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.card {
  cursor: pointer;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1rem;
  border-radius: 10px;
}
.card:not(:first-child) {
  margin-top: 1rem;
}
.card-title {
  display: flex;
  justify-content: space-between;
}
.arrow {
  transition: all 0.3s ease-in-out;
}

.activeArrow:lang(en) {
  transform: rotate(90deg);
}
.activeArrow:lang(ku) {
  transform: rotate(-90deg);
}
.bold {
  font-weight: bold;
}
